import { StaticImage } from "gatsby-plugin-image"
import React, { VFC } from "react"
import { getBgImage, getBgWavePc, getBgWaveSp, Target } from "../../../utils"

type Props = {
  isDo?: boolean
  target: Target
}

const About: VFC<Props> = ({ target, isDo }) => {
  return (
    <div
      style={{
        margin: `4rem calc(50% - 50vw + 8px) 0`,
      }}
    >
      <div
        className={`h-4 bg-repeat-x md:h-8 ${getBgWaveSp(target)} ${getBgWavePc(
          target
        )}`}
      ></div>
      <div className={`bg-repeat-round ${getBgImage(target)}`}>
        <div className="max-w-[960px] mx-auto px-4">
          {/* <div
            className={
              isDo ? "hidden" : "grid-cols-2 text-center md:grid pt-28"
            }
          >
            <div>
              <p className="text-2xl md:text-3xl font-kiwiMaru">
                児童発達支援事業とは
              </p>
              <div className="mt-10">
                　児童福祉法に基づく障がい児通所支援事業です。
                未就学児対象に支援充実のため設立された制度であり、日常生活をおくるのに必要な基本動作や知識などを習得し、集団生活や社会生活に適応できるよう療育の場として利用ができ、ご家族に代わり一時的にケアを代替えする支援サービスの役割を担います。
                原則として　未就学児　が対象となっております。
              </div>
            </div>
            <StaticImage
              src="../../../images/shared/girl.png"
              alt="girl"
              className="w-40 mx-auto mt-8 md:mt-0"
              width={165}
            />
          </div> */}
          <div
            className={`flex flex-col-reverse grid-cols-2 text-center md:grid pb-28 ${
              isDo ? "pt-28" : "pt-14"
            }`}
          >
            <StaticImage
              src="../../../images/shared/rainbow.png"
              alt="rainbow"
              className="mx-auto w-72"
            />
            <div>
              <p className="text-2xl md:text-3xl font-kiwiMaru">
                放課後等デイサービスとは
              </p>
              <div className="mt-10">
                児童福祉法に基づく障がい通所支援事業です。学齢期における支援充実のため設立された制度であり、学校に就学している発達に凸凹がある子ども達が放課後や長期休業中の療育の場として利用でき、ご家族に代わり一時的にケアを代替えする支援サービスの役割を担います。
                原則として　就学児童　が対象となっております。
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`h-4 rotate-180 bg-repeat-x md:h-8 ${getBgWaveSp(
          target
        )} ${getBgWavePc(target)}`}
      ></div>
    </div>
  )
}

export default About
