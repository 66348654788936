import React from "react"
import Feature from "../shared/top/Feature"
import HeadingM from "../shared/HeadingM"

const TopFeature = () => {
  const items = [
    {
      title: "習い事のような放課後等デイサービス",
      descriptions: [
        "県認可の児童福祉法に基づく 通所型支援事業です。",
        "利用料金は児童福祉法に定めるサービス料金の1割負担となっております。",
      ],
    },
    {
      title: "安心の児童専門家",
      descriptions: [
        "児童発達管理責任者・保育士・臨床心理士・トレーナー・療育に造詣の深い専門家が支援いたします。",
      ],
    },
    {
      title: "プロのスポーツ選手の協力のもと行うスポーツ活動",
      descriptions: [
        "お子さまの楽しめる運動プログラムを用意しています。",
        "保護者様も一緒に活動できるプログラムも実施します。",
      ],
    },
    {
      title: "保護者様のプログラム実施",
      descriptions: [
        "保護者様も一緒に活動できるプログラムを実施します。",
        "保護者様専用のプログラムも実施します。",
        "保護者様が過ごせる共有スペースがあります。日々の活動を見たり、一緒に参加したりと開かれた透明性のある事業所です。",
        "保護者様同士の相談の場としてもご利用いただけます。",
      ],
    },
    // {
    //   title: "臨床心理士による個別療育",
    //   descriptions: [
    //     "お子さまと一対一での個別療法を行います。また、保護者様の相談も行います。",
    //     "身体機能の発達支援の他に個別療育支援も充実しています。",
    //   ],
    // },
    {
      title: "送迎サービスも実施しています",
      descriptions: [
        "学校、ご家庭、その他前もってお伝えいただければ送迎を行います。",
      ],
    },
  ]

  return (
    <div className="mt-24">
      <HeadingM title="nicoの特徴" target="nico" />
      <Feature items={items} target="nico" />
    </div>
  )
}

export default TopFeature
