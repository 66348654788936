import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../components/nico/Layout"
import TopContact from "../../components/nico/TopContact"
import TopFeature from "../../components/nico/TopFeature"
import TopInstagram from "../../components/nico/TopInstagram"
import TopPartners from "../../components/nico/TopPartners"
import TopSlider from "../../components/nico/TopSlider"
import SEO from "../../components/Seo"
import About from "../../components/shared/top/About"
import Concept from "../../components/shared/top/Concept"
import OpeningShutter from "../../components/shared/top/OpeningShutter"

const query = graphql`
  query {
    logo: file(relativePath: { eq: "nico/logo.png" }) {
      childImageSharp {
        gatsbyImageData(width: 100)
      }
    }
  }
`

const index = () => {
  const data = useStaticQuery(query)
  return (
    <Layout>
      <SEO siteTitle="nico" />
      <OpeningShutter logo={getImage(data.logo)} target="nico" />
      <TopSlider />
      <Concept name="nico" bgDotes="md:bg-nicoFourDots" />
      <About target="nico" />
      <TopFeature />
      <TopInstagram />
      <TopPartners />
      <TopContact />
    </Layout>
  )
}

export default index
